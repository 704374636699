.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    background-color: #293237;
    color: white;
  }

.header img {
    max-width: 120px;
    max-height: 80px;
  }

  .header ul a {
    margin-right: 25px;
  }
  
  .logo {
    font-size: 1.5rem;
  }

  .logo-link {
    padding: 0;
    margin: 0;
  }
  

  .logo-link:hover, .logo-link:active {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }

  .logo-link {
    transform: scale(1);
    transition: transform 0.3s ease;
  }

  
  
  /* Hamburger for mobile */
  .hamburger {
    display: none;
    font-size: 2rem;
    cursor: pointer;
    color: #fff;
  }
  
  .nav {
    margin-right: 2%;
  }

  .nav ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
  }
  
  .nav ul li {
    text-align: center;
  }
  
  .nav ul li a {
    color: white;
    text-decoration: none;
    display: block;
    font-size: 1.2rem;
  }
  
  /* Media query for mobile screens */
  @media (max-width: 768px) {
    .hamburger {
      display: block; /* Show hamburger on small screens */
    }

    .nav {
        z-index: 7;
    }
  
    .nav ul {
      position: fixed;
      top: 0;
      margin: 0;
      left: 110vw;
      width: 200px;
      height: 100vh;
      background-color: #333;
      flex-direction: column;
      transition: left 0.3s ease-in-out;
      padding-top: 40px;
      box-shadow: 0 0 20px 5px black;
      justify-content: flex-start;
      align-items: center;
    }
  
    .nav.open ul {
      left: calc(100vw - 240px);
    }
  
    .nav ul li {
      margin: 20px 0;
      width: 100%;
      text-align: center;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 5;
    }
  }