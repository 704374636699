.footer {
  background-color: #293237;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px 5px 20px;
}

.footer-icons {
  display: flex;
  gap: 1.25rem;
  margin-right: 2%;
}

.footer-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin: 10px;
  color: white;
  transition: color 0.3s ease;
}

.footer-icons svg {
  width: 20px;
  height: 20px;
}

.footer a:hover {
  color: #aaa;
}