.content-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 0;
    background-color: white;
    min-height: calc(100vh - 230px);
  }
  
  .main-content {
    margin-top: 2rem;
  }
  
  /* Headings */
  .main-heading {
    color: #c25511;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    position: relative;
    padding-bottom: 1rem;
  }
  
  .main-heading::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background-color: #c25511;
  }
  
  .section-heading {
    color: #c25511;
    font-size: 2rem;
    margin: 2rem 0 1.5rem;
    border-left: 5px solid #c25511;
    padding-left: 1rem;
  }
  
  /* Hero Section */
  .hero-section {
    background-color: #fff5f0;
    padding: 2rem;
    border-radius: 8px;
    margin: 2rem 0;
  }
  
  .hero-text {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
  }
  
  /* Feature Grid */
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 2rem 0;
  }
  
  .feature-card {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(194, 85, 17, 0.1);
    transition: transform 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
  }
  
  .feature-title {
    color: #c25511;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  /* Lists */
  .custom-list {
    padding-left: 1.5rem;
    margin: 1.5rem 0;
  }
  
  .custom-list li {
    margin-bottom: 1rem;
    position: relative;
    padding-left: 1.5rem;
    line-height: 1.6;
  }
  
  .custom-list li::before {
    content: '•';
    color: #c25511;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    top: -0.2rem;
  }
  
  /* Info Box */
  .info-box {
    background-color: #fff5f0;
    border-left: 5px solid #c25511;
    padding: 1.5rem;
    margin: 2rem 0;
    border-radius: 0 8px 8px 0;
  }
  
  .info-heading {
    color: #c25511;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  
  /* Text Columns */
  .text-columns {
    column-count: 2;
    column-gap: 2rem;
    margin: 1.5rem 0;
    line-height: 1.6;
  }
  
  /* Highlight Text */
  .highlight-text {
    font-size: 1.1rem;
    color: #333;
    line-height: 1.6;
    background-color: #fff5f0;
    padding: 1rem;
    border-radius: 8px;
    margin: 1.5rem 0;
  }

  .regular-text {
    font-size: 1.1rem;
    color: #333;
    line-height: 1.6;
    margin: 1.5rem 0;
    text-indent: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .text-columns {
      column-count: 1;
    }
  
    .feature-grid {
      grid-template-columns: 1fr;
    }
  
    .main-heading {
      font-size: 2rem;
    }
  
    .section-heading {
      font-size: 1.75rem;
    }
  }