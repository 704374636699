.home-page {
    height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


@media (max-width: 768px) {
    .home-page {
        margin: 0;
    }
} 