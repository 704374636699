/* Container styling for the timeline */
.timeline-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  overflow: hidden;
}

/* Header styling */
.timeline-header {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  color: #c25511;
}

/* Vertical timeline line */
.timeline-line {
  position: absolute;
  width: 2px;
  background-color: #ccc;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  top: 110px;
}

/* Add media query for mobile devices */
@media screen and (max-width: 768px) {
  .timeline-line {
    top: 150px;
  }
  
  .timeline-header {
    margin-bottom: 30px;
  }
}

/* Styling for each timeline item */
.timeline-item {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 6%;
  margin-bottom: 40px;
  padding: 40px;

  position: relative;
}

.timeline-item-left {
  justify-content: flex-end;
  padding-right: 10%;
}

.timeline-item-right {
  justify-content: flex-start;
  padding-left: 10%;
}

/* Dot in the timeline */
.timeline-dot-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-dot {
  width: 16px;
  height: 16px;
  background-color: #c25511;
  border-radius: 50%;
}

/* Content of the timeline item */
.timeline-content {
  width: 40%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timeline-year {
  font-size: 1rem;
  font-weight: bold;
  color: #c25511;
}

.timeline-title {
  font-size: 1.5rem;
  margin-top: 10px;
}

.timeline-image {
  margin-top: 10px;
}

.timeline-img {
  width: 128px;
  height: 128px;
  object-fit: cover;
}

.timeline-text {
  margin-top: 10px;
  color: #555;
  font-size: 1rem;
}

/* Center the image within the container */
.ms-auto {
  margin-left: auto;
  margin-right: auto;
}

.me-auto {
  margin-left: auto;
  margin-right: auto;
}
